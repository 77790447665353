import React from "react";

import { SvgArrowLeft } from "@components/Svg/SvgStore";

import st from "./CarouselButton.module.scss";

export function CarouselButtonNext({ disabled, onClick, ...props }) {
  return (
    <button
      className={`${st["carousel-button-next"]} ${disabled ? st["disabled"] : ""}`}
      onClick={onClick}
      {...props}
    >
      <SvgArrowLeft height="18px" />
    </button>
  );
}

export function CarouselButtonPrev({ disabled, onClick, ...props }) {
  return (
    <button
      className={`${st["carousel-button-prev"]} ${disabled ? st["disabled"] : ""}`}
      onClick={onClick}
      {...props}
    >
      <SvgArrowLeft height="18px" />
    </button>
  );
}

export function CarouselDotButton({ active, onClick, ...props }) {
  return (
    <button
      className={`${st["carousel-button-dot"]} ${active ? st["active"] : ""}`}
      onClick={onClick}
      {...props}
    />
  );
}
