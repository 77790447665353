import React from "react";

import Link from "next/link";

export function LinkTo({
  ariaLabel = "",
  children,
  link = "/",
  text = "",
  ...props
}) {
  const acessibility = ariaLabel || text;
  const linkContent = children || text;

  return (
    <Link
      aria-label={acessibility}
      className="linkTo-style"
      href={link}
      passHref={true}
      {...props}
    >
      {linkContent}
    </Link>
  );
}
