import PubSub from "@lib/pubsub";
import { useCallback, useEffect, useState } from "react";

export function useWindowWidth() {
  const [stateWidth, setStateWidth] = useState(
    typeof window !== "undefined"
      ? window.innerWidth
      : PubSub.state[PubSub.EVENTS.MOBILE]
        ? 0
        : 1920
  );

  const handleResize = useCallback(() => {
    setStateWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return stateWidth;
}
