import API from "@lib/api/api";

import { logError } from "@lib/tools/logger";
import useSWR, { unstable_serialize } from "swr";

export const getRecentGames = ([url, token], headers = {}) => {
  if (!token) return Promise.reject(null);

  return API.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
  })
    .then((res) => res.data?.result)
    .catch((err) => {
      logError(err);
      return [];
    });
};

export const useRecentGames = (token, initialData = []) => {
  const fb = unstable_serialize(["user/recent-games", token]);
  return useSWR(token ? ["user/recent-games", token] : null, getRecentGames, {
    keepPreviousData: true,
    fallback: {
      [fb]: initialData,
    },
    revalidateOnMount: !initialData?.length,
    revalidateOnFocus: false,
  });
};
