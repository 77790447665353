import React from "react";
import st from "../RankBanner.module.scss";

export function RankBannerSkeleton() {
  return (
    <div className={`${st["rank-banner-container"]} ${st["skeleton"]}`}>
      <div className={st["title-container"]}>
        <div className={st["skeleton-title"]}></div>
      </div>

      <div className={`${st["rank-container"]} ${st["skeleton-container"]}`}>
        <div className={st["skeleton-content"]}>
          <div className={st["skeleton-row"]}>
            <div className={st["skeleton-text"]}></div>
          </div>

          <div className={st["progress-bar-container"]}>
            <div className={`${st["progress-bar"]} ${st["skeleton-bar"]}`}>
              <div className={st["bar"]} style={{ width: `${0}%` }}></div>
            </div>
          </div>

          <div className={st["user-rank-container"]}>
            <div className={st["skeleton-rank"]}>
              <div className={st["skeleton-text"]}></div>
            </div>
            <div className={st["skeleton-rank"]}>
              <div className={st["skeleton-text"]}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
