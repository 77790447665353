import React from "react";

import st from "./Button.module.scss";

/**
 * @deprecated this is a duplicate use the index one
 */
export function Button({
  ariaLabel = "",
  children,
  text = "",
  typeButton = "button",
  ...props
}) {
  const acessibility = ariaLabel || text;
  const content = children || text;

  return (
    <button
      aria-label={acessibility}
      className={st["button-style"]}
      type={typeButton}
      {...props}
    >
      {content}
    </button>
  );
}
