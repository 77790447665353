import { SVGProps } from "react";

export default function SteamIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_846_94185)">
        <path
          d="M0.41125 12.9163C1.03849 14.9668 2.30748 16.7618 4.03142 18.037C5.75536 19.3123 7.84316 20.0004 9.9875 20C15.5175 20 20 15.5225 20 10C20 4.4775 15.5175 7.4973e-07 9.98875 7.4973e-07C7.45034 -0.000981229 5.00641 0.962699 3.15182 2.69591C1.29723 4.42912 0.17058 6.80233 0 9.335L0.00375 9.3425L5.38375 11.5538C5.86898 11.2325 6.44367 11.0736 7.025 11.1L9.475 7.545L9.47375 7.495C9.47474 6.4867 9.87564 5.51997 10.5885 4.80688C11.3014 4.09379 12.268 3.69257 13.2763 3.69125C14.2845 3.69257 15.2511 4.09379 15.964 4.80688C16.6769 5.51997 17.0778 6.4867 17.0788 7.495C17.0784 8.00166 16.9769 8.50315 16.7803 8.97011C16.5837 9.43706 16.2959 9.8601 15.9337 10.2144C15.5716 10.5688 15.1424 10.8473 14.6713 11.0337C14.2001 11.2201 13.6965 11.3106 13.19 11.3L9.685 13.8C9.69689 14.2631 9.59282 14.7218 9.38226 15.1344C9.17169 15.547 8.86131 15.9004 8.47935 16.1625C8.09739 16.4246 7.65596 16.5871 7.19522 16.6351C6.73448 16.6831 6.26905 16.6152 5.84125 16.4375C5.43065 16.2664 5.06728 15.999 4.78183 15.6578C4.49638 15.3167 4.29724 14.9118 4.20125 14.4775L0.4125 12.9163H0.41125Z"
          fill="#818597"
        />
        <path
          d="M6.08492 15.8537C6.60571 16.0525 7.18346 16.0406 7.69563 15.8205C8.2078 15.6005 8.61412 15.1896 8.82842 14.675C9.04272 14.1604 9.04814 13.5826 8.84353 13.064C8.63892 12.5455 8.24038 12.127 7.73242 11.8974C7.22878 11.6873 6.66371 11.6783 6.15367 11.8724L7.43242 12.4024C7.62366 12.482 7.79734 12.5985 7.94356 12.7453C8.08977 12.892 8.20565 13.0661 8.28458 13.2576C8.36351 13.4492 8.40394 13.6544 8.40357 13.8615C8.4032 14.0686 8.36204 14.2737 8.28242 14.4649C8.20281 14.6562 8.08631 14.8299 7.93957 14.9761C7.79283 15.1223 7.61873 15.2382 7.42721 15.3171C7.23569 15.396 7.0305 15.4365 6.82335 15.4361C6.61621 15.4357 6.41116 15.3945 6.21992 15.3149L4.98242 14.8024C5.20762 15.2766 5.60035 15.6519 6.08492 15.8537ZM10.7424 7.49493C10.7428 8.16705 11.0097 8.81159 11.4848 9.28708C11.9598 9.76258 12.6041 10.0302 13.2762 10.0312C13.9483 10.0302 14.5926 9.76258 15.0676 9.28708C15.5426 8.81159 15.8096 8.16705 15.8099 7.49493C15.8089 6.82324 15.5417 6.17935 15.0667 5.7044C14.5918 5.22944 13.9479 4.96217 13.2762 4.96118C12.6045 4.96217 11.9606 5.22944 11.4856 5.7044C11.0107 6.17935 10.7434 6.82324 10.7424 7.49493ZM13.2799 5.58618C13.5301 5.58635 13.7778 5.63578 14.0088 5.73167C14.2399 5.82756 14.4498 5.96801 14.6266 6.14503C14.8034 6.32204 14.9436 6.53214 15.0391 6.76332C15.1347 6.99451 15.1838 7.24226 15.1837 7.49243C15.1835 7.7426 15.1341 7.99029 15.0382 8.22135C14.9423 8.45241 14.8018 8.66232 14.6248 8.8391C14.4478 9.01588 14.2377 9.15607 14.0065 9.25165C13.7753 9.34723 13.5276 9.39635 13.2774 9.39618C12.7722 9.39585 12.2878 9.19483 11.9308 8.83734C11.5737 8.47984 11.3733 7.99517 11.3737 7.48993C11.374 6.98469 11.575 6.50028 11.9325 6.14326C12.29 5.78624 12.7747 5.58585 13.2799 5.58618Z"
          fill="#818597"
        />
      </g>
      <defs>
        <clipPath id="clip0_846_94185">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
