import { useEffect } from "react";
import { useUserSession } from "./useUserSession";
import { useRouter } from "next/router";

/**
 * useAuth - Custom hook for handling user authentication
 *
 * This hook will check if the user should be allowed on a particular page
 * based on their authentication status. If the user is not allowed,
 * they will be redirected to the home page.
 *
 * @param {boolean} noAuth - Flag to specify pages where unauthenticated
 *                            users are not allowed. True means users aren't allowed but guests are.
 */

//Checks if the users are allowed on certain pages, otherwise redirects
const useAuth = (noAuth = false) => {
  const router = useRouter();
  const { hasToken, hasUserData, userChecked } = useUserSession({
    required: !noAuth,
  });

  useEffect(() => {
    if (!userChecked) return;

    if (noAuth && hasUserData) {
      router.replace(router.asPath.split("?")[0], undefined, {
        shallow: true,
      });
    }
  }, [hasUserData, noAuth, router, userChecked]);

  return hasToken;
};

export default useAuth;
