import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import GamesIcons from "../items/GamesIcons";
import FairButton from "./FairButton";
import GlobalSoundManager from "../items/GlobalSoundManager";

// A helper function to get the game title based on the game URL
const getGameTitle = (game) => {
  const gameTitles = {
    crash: "Crash",
    "case-opening": "Case Open",
    "gold-chests": "Gold Chests",
    dice: "Dice",
    plinko: "Plinko",
    roulette: "Roulette",
    keno: "Keno",
    mines: "Mines",
  };

  return gameTitles[game] || (game ? "Slots" : "No Game");
};

// The GameHeading component
export default function GameHeading() {
  const router = useRouter();
  const [currentGame, setCurrentGame] = useState({});

  useEffect(() => {
    if (router.query.game) {
      const gameTitle = getGameTitle(router.query.game);
      setCurrentGame({ title: gameTitle });
    }
  }, [router.query.game]);

  // Check if sound manager should be rendered
  const shouldRenderSoundManager = [
    "Dice",
    "Plinko",
    "Roulette",
    "Keno",
    "Mines",
  ].includes(currentGame.title);

  // Determine if the 'case-opening' game should be styled differently
  const isCaseOpening = router.query.game === "case-opening";

  return (
    <>
      <div
        className={`${isCaseOpening ? "pt-[25px]" : ""} flex items-center justify-between pt-[25px] w-full pb-4`}
      >
        <div className="flex gap-2 items-center">
          <GamesIcons code={currentGame?.title} />
          <h1 className="text-[17px]">{currentGame?.title}</h1>
        </div>

        {/* Conditional rendering of the navigation section */}
        {router.query.game !== "case-opening" && (
          <div className="flex gap-[10px] items-center">
            {/* Render GlobalSoundManager for specific games */}
            {shouldRenderSoundManager && <GlobalSoundManager />}

            {/* FairButton is always rendered */}
            <FairButton />
          </div>
        )}
      </div>
    </>
  );
}
