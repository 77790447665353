import React from "react";
import Image from "next/image";

import useLanguage from "../../hooks/useLanguage";

import {
  SvgApplePay,
  SvgGooglePay,
  SvgMastercard,
  SvgPix,
  SvgVisa,
} from "@components/Svg/SvgStore";

import Cryptos from "../../assets/Images/cryptos.png";
import Interac from "../../assets/Images/interac.png";

import st from "./Banner.module.scss";
import st2 from "./PaymentBanner.module.scss";
import { useWindowWidth } from "@hooks/useWindowWidth";
import { SvgPaymentMethod } from "@assets/icons/PaymentMethods/SvgPaymentMethod";

export function PaymentBanner() {
  const L = useLanguage(["pageContent", "common"]);
  const currentWidth = useWindowWidth();

  return (
    <div className={st["banner-container"]}>
      <div className={st2["banner-row-payment"]}>
        <div
          className={
            currentWidth < 1023
              ? st["row-heading-text"]
              : st2["row-heading-text"]
          }
        >
          <div className={st2["row-svg-title"]}>
            <div className={st2["svg-container"]}>
              <SvgPaymentMethod />
            </div>

            <span className={st2.h3}>
              {L("homeBanner.payment_row.heading")}
            </span>
          </div>

          <p>{L("homeBanner.payment_row.subheading")}</p>
        </div>

        <div
          style={
            currentWidth > 380
              ? {
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }
              : {
                  alignItems: "center",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  rowGap: "10px",
                }
          }
        >
          <div className={st2["payment-methods"]}>
            <div style={{ width: "100px" }}>
              <Image
                src={Cryptos}
                alt="Cryptos"
                sizes="100vw"
                style={{
                  height: "100%",
                  objectFit: "cover",
                  width: "100%",
                }}
              />
            </div>

            <div>
              <span
                style={
                  currentWidth < 400
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                &&nbsp;more
              </span>
            </div>
          </div>

          <div className={st2["vertical-line"]} />

          <div className={st2["payment-methods"]}>
            <div className={st2["payment-box"]}>
              <SvgPix />
            </div>

            <div style={{ width: "26px" }}>
              <Image
                src={Interac}
                alt="Interac"
                sizes="100vw"
                style={{
                  height: "100%",
                  objectFit: "cover",
                  width: "100%",
                }}
              />
            </div>
          </div>

          <div className={st2["vertical-line"]} />

          <div className={st["row-svg-title"]}>
            <div className={st2["payment-methods"]}>
              <div className={st2["display-flex"]}>
                <div className={`${st2["payment-box"]}`}>
                  <SvgVisa />
                </div>

                <div className={`${st2["payment-box"]}`}>
                  <SvgMastercard />
                </div>
              </div>

              <div className={st2["display-flex"]}>
                <div className={`${st2["payment-box"]}`}>
                  <SvgApplePay />
                </div>

                <div className={`${st2["payment-box"]}`}>
                  <SvgGooglePay />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
