import React, { useEffect, useRef, useState } from "react";

import Image from "next/image";
import dynamic from "next/dynamic";
import Head from "next/head";

import st from "@styles/Home.module.scss";
import stCarousel from "@components/Carousel/CarouselHomeBanners.module.scss";

import { Banner } from "@components/Banner/Banner";
import { CarouselHomeBanners } from "@components/Carousel/CarouselHomeBanners";
import { LoggedOutBanner } from "@components/Banner/LoggedOutBanner";
import { PaymentBanner } from "@components/Banner/PaymentBanner";
import { RankBanner } from "@components/Banner/RankBanner";
import { RewardsBannerMonthly } from "@components/Banner/RewardsBannerMonthly";
import { RewardsBannerWeekly } from "@components/Banner/RewardsBannerWeekly";
import MainBannerBackground from "@Games/Raffle/components/raffle-banner/main-banner-background";
import SnowingBackground from "@Games/Raffle/components/raffle-banner/snowing-background";
import { getRecentGames, useRecentGames } from "@components/RecentGames";

const LiveFeed = dynamic(() => import("@components/LiveFeed"));
const ProviderList = dynamic(() => import("@components/ProviderList"));
const SlotsList = dynamic(() => import("@components/SlotsList"));
const MetaContent = dynamic(() => import("@items/MetaContent"));
import useLanguage from "@hooks/useLanguage";
import useMobile from "@hooks/useMobile";
import { useUserSession } from "@hooks/useUserSession";
import { getSlotList } from "@components/SlotsList";
import { NODE_API } from "@lib/api/api";
import {
  mergeClasses,
  classnames,
  getClientIp,
  getDeviceInfo,
} from "@lib/tools/helpers";
import { inHouseGames } from "@lib/tools/house-games";
import { logError } from "@lib/tools/logger";
import { fetchPageContent } from "@lib/tools/ssr";
import XmasCharacter from "@assets/Images/xmasCharacter.png";
import Accordion from "@components/Accordion/v2";
import { Content } from "@components/BlogPage";
import useRewards from "@hooks/useRewards";

const schema = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  name: "Rainbet",
  url: "https://rainbet.com/",
};

const BannerImg = ({
  aspectRatio = "",
  chatMobileTwoPromo,
  promoIsFalse,
  mobileImage,
  ...props
}) => {
  const [stateLoaded, setStateLoaded] = useState(false);

  return (
    <div
      data-img
      {...mergeClasses(
        chatMobileTwoPromo,
        promoIsFalse,
        st["custom-height-image"],
        mobileImage && st["mobile-image"]
      )}
    >
      <MainBannerBackground />
    </div>
  );
};

const CharacterImg = ({ mobileImage, ...props }) => {
  return (
    <div
      {...mergeClasses(mobileImage && st["mobile-image"])}
      style={{ display: "block", width: "100%" }}
    >
      <Image
        {...props}
        alt="Character image"
        src={XmasCharacter}
        priority
        style={{
          position: "relative",
          top: "19px",
          display: "flex",
          objectFit: "cover",
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
};

// Main functional component for the Home page
export default function Home(props) {
  const {
    chat,
    content,
    recentGames: recentGames_,
    recommended,
    initialNow,
    ip,
  } = props;
  const L = useLanguage(["common", "meta", "Slots"]);
  const { hasToken, token } = useUserSession();
  const isMobile = useMobile();
  const isMobileBanner = useMobile({
    breakPointOne: 1100,
    breakPointTwo: 853,
    breakPointThree: 893,
  });

  const { data: recentGames } = useRecentGames(token, recentGames_);
  const { data: rewardsData } = useRewards();
  const sliderRef = useRef(null);
  const [debug, setDebug] = useState({
    device: null,
    ip,
  });
  const scrollTo = sliderRef.current?.scrollTo;
  const [scrollToIndex, setScrollToIndex] = useState(0);

  useEffect(() => {
    if (!scrollToIndex) return;

    scrollTo?.(scrollToIndex);
  }, [scrollToIndex, scrollTo]);

  const mobileImage = chat && isMobileBanner === "mobile-three";

  useEffect(() => {
    getDeviceInfo().then((a) =>
      setDebug((prev) => ({
        ...prev,
        device: a,
      }))
    );
  }, []);

  return (
    <main
      {...classnames(st, "home-container", isMobile)}
      data-debug={JSON.stringify({
        device: JSON.stringify(debug.device),
        ip: debug.ip,
      })}
    >
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schema),
          }}
        />
      </Head>

      <MetaContent meta={content?.meta} />
      {hasToken ? (
        <div
          className={`${st["banner-container-carousel"]} ${chat && isMobileBanner ? st[isMobileBanner] : null}`}
        >
          <SnowingBackground
            snowflakeCount={10}
            className="z-[81] pointer-events-none"
          />
          <BannerImg />

          <div className={st["overlay"]}>
            <div className={st["flex-container"]}>
              <CarouselHomeBanners emblaRef={sliderRef}>
                <div className={stCarousel["carousel-item"]} key={0}>
                  <div className={stCarousel["carousel-card"]}>
                    <RankBanner />
                  </div>
                </div>
                <div className={stCarousel["carousel-item"]} key={1}>
                  <div className={stCarousel["carousel-card"]}>
                    <RewardsBannerWeekly
                      rewardsData={rewardsData}
                      initialNow={initialNow}
                      changeScroll={() => {
                        setScrollToIndex((i) => (i !== 2 ? 1 : i));
                      }}
                    />
                  </div>
                </div>
                <div className={stCarousel["carousel-item"]} key={2}>
                  <div className={stCarousel["carousel-card"]}>
                    <RewardsBannerMonthly
                      rewardsData={rewardsData}
                      initialNow={initialNow}
                      changeScroll={() => {
                        setScrollToIndex(2);
                      }}
                    />
                  </div>
                </div>
              </CarouselHomeBanners>
            </div>

            <div className={st["character-container"]}>
              <CharacterImg mobileImage={mobileImage} />
            </div>
          </div>
        </div>
      ) : (
        <LoggedOutBanner chat={chat} heading={content?.heading} />
      )}

      <Banner />
      <LiveFeed initialData={props.gamesHistory} />

      {/* Popular Slots Section */}
      <SlotsList
        heading={"recommended"}
        sortBy="recommended"
        carousel
        initialData={recommended?.games}
        count={recommended?.count}
      />
      <SlotsList
        isStatic
        carousel={isMobile === "mobile-four"}
        heading={"rainbetGames"}
        initialData={inHouseGames}
        number={inHouseGames.length}
      />

      {recentGames?.length ? (
        <SlotsList
          isStatic
          heading={"home.recently_played_games"}
          carousel
          initialData={recentGames}
          number={recentGames.length}
        />
      ) : null}

      {!hasToken && <PaymentBanner />}

      <ProviderList
        heading={L("providers")}
        producersOnly
        carousel
        default_sort_by={"popular"}
        grouping={"slots"}
        isHome
      />

      {content?.content && (
        <Accordion
          header={<div>About Rainbet Crypto Casino</div>}
          className={st.accordion}
          variant="second padding"
        >
          <Content __html={content?.content} />
        </Accordion>
      )}
    </main>
  );
}

/**
 *
 * @param { import("next").GetServerSidePropsContext} context
 */
export async function getServerSideProps(context) {
  try {
    context.res.setHeader(
      "Cache-Control",
      "public, s-maxage=10, stale-while-revalidate=59"
    );

    const { authOptions } = await import("@pages/api/auth/[...nextauth]");
    const { getServerSession } = await import("next-auth");

    const session = await getServerSession(
      context.req,
      context.res,
      authOptions
    );
    const token = session?.user?.access_token;
    const headers = {
      "ssr-secret-yel8wd1sa": process.env.SSR_SECRET,
      "user-agent": context.req.headers["user-agent"],
      cookie: context.req.headers.cookie,
      "ssr-client-ip": getClientIp(context),
      ...(token && { Authorization: `Bearer ${token}` }),
    };

    const [content, recommended, recentGames, gamesHistory] = await Promise.all(
      [
        fetchPageContent(context),
        getSlotList(
          {
            sort_by: "recommended",
            num: 36,
          },
          headers
        ).catch(() => []),
        getRecentGames(["user/recent-games", token], headers).catch(() => []),
        NODE_API.post("v1/game-history", { wins: true, take: 12 }, { headers })
          .then((res) => res.data)
          .catch(() => []),
      ]
    );

    return {
      props: {
        content,
        recentGames,
        recommended,
        initialNow: Math.floor(Date.now() / 1000),
        gamesHistory,
        ip: headers["ssr-client-ip"] || null,
      },
    };
  } catch (error) {
    logError(
      "pages/index.jsx",
      "getServerSideProps",
      "Failed to fetch data",
      error.toString()
    );
    return {
      props: {
        error: error.toString(),
      },
    };
  }
}
