import React, { useCallback } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import useAuth from "@hooks/useAuth";
import useLanguage from "@hooks/useLanguage";
import useMobile from "@hooks/useMobile";
import usePopup from "@hooks/usePopup";
import { useUserSession } from "@hooks/useUserSession";
import API from "@lib/api/api";
import { removeUrlQueries } from "@lib/tools/helpers";
import SnowingBackground from "@Games/Raffle/components/raffle-banner/snowing-background";
import { Button } from "../Button/Button";
import CircleArrowIcon from "@assets/icons/general/CircleArrowIcon";
import MetaMaskIcon from "@assets/icons/general/MetaMaskIcon";
import SteamIcon from "@assets/icons/general/SteamIcon";
import { motion } from "framer-motion";
import { UseSessionOptions } from "next-auth/react";
import xmasCharacter from "@assets/Images/xmasCharacter.png";
import MainBannerBackground from "@Games/Raffle/components/raffle-banner/main-banner-background";
import { cn } from "@lib";

export function LoggedOutBanner({ chat, heading = "Welcome to RainBet" }) {
  const L = useLanguage(["pageContent", "common"]);

  const { push } = useRouter();
  const { signInToken: login } = useUserSession({} as UseSessionOptions<any>);
  const setMessage = usePopup();
  useAuth(true);
  const router = useRouter();
  const isMobile = useMobile({
    breakPointOne: 888,
    breakPointTwo: 950,
    breakPointThree: 790,
  });

  //First processes for steam
  const steam = useCallback(() => {
    API.post("steam/initiate")
      .then((res) => {
        push(res.data.url);
      })
      .catch((err) => {
        if (err.response?.status !== 401) {
          setMessage({ code: "responses.er_network", type: 0 });
        }
      });
  }, [push, setMessage]);

  const metaMaskLogin = useCallback(async () => {
    try {
      const provider = window.ethereum;
      if (!provider) {
        setMessage({ code: "responses.metamask_not_installed", type: 0 });
        throw new Error(
          "MetaMask is not installed. Please install it to proceed."
        );
      }

      if (!provider.isMetaMask) {
        setMessage({ code: "responses.only_meta_mask", type: 0 });
        throw new Error("This feature is only available for MetaMask users.");
      }

      const accounts = await provider.request({
        method: "eth_requestAccounts",
      });

      const dataOne = {
        address: accounts[0],
        stage: 1,
      };

      const resOne = await API.post("metamask/initiate", dataOne);

      if (resOne.data.error) {
        setMessage({ code: "responses." + resOne.data.error, type: 0 });
      }

      if (resOne.data.success) {
        const localizedMessage = L("meta_mask_message", {});
        const message = `${localizedMessage} ${resOne.data.hash}`;

        const signature = await provider.request({
          method: "personal_sign",
          params: [message, accounts[0]],
        });

        const dataTwo = {
          signature: signature,
          localized_message: localizedMessage,
          address: accounts[0],
          stage: 2,
        };

        const resTwo = await API.post("metamask/initiate", dataTwo);

        if (resTwo.data.tokens) {
          login(resTwo.data.tokens);
          setMessage({ code: "responses.successful", type: 1 });

          removeUrlQueries();
        } else {
          setMessage({ code: "responses." + resTwo.data.error, type: 0 });
        }
      }
    } catch (err) {
      if (err.response?.status === 401) {
        setMessage({ code: "responses.er_network", type: 0 });
      }
    }
  }, [L, login, setMessage]);

  const loginModal = () => {
    router.push({ query: { modal: "auth", tab: "login" } });
  };

  return (
    <motion.div
      className="@container flex h-full w-full justify-between gap-0 sm:gap-[40px] relative mb-5 shadow-[0_0_30px_0_rgba(0,119,219,.3)] rounded-xl "
      animate={{
        width: "100%",
      }}
      style={{
        border: "2.5px solid #FF810D",
      }}
      transition={{ duration: 0.2 }}
    >
      <MainBannerBackground />

      <div className="relative z-1">
        <div className="flex flex-col justify-center gap-5 m-8 w-[600px]">
          <motion.h1
            className="text-base sm:text-[28px] font-bold leading-[1.25] "
            transition={{ duration: 0.2 }}
          >
            {heading || L("homeBanner.loggedOut_banner.welcome", {})}
          </motion.h1>

          <div className=" font-medium leading-tight break-words md:text-sm sm:text-xs">
            <span className="text-[rgba(232,229,255,0.85)]">
              {L("homeBanner.loggedOut_banner.firstSpan", {})}{" "}
              <span className="text-[#FFA000]">
                {L("homeBanner.loggedOut_banner.secondSpan", {})}
              </span>{" "}
              {L("homeBanner.loggedOut_banner.thirdSpan", {})}
              <span className="text-[#FFA000]">
                {L("homeBanner.loggedOut_banner.fourthSpan", {})}
              </span>{" "}
              {L("homeBanner.loggedOut_banner.fifthSpan", {})}
            </span>
          </div>

          <motion.div
            className="flex items-center gap-[18px]"
            animate={{
              gap: isMobile || chat ? "8px" : "18px",
            }}
            transition={{ duration: 0.2 }}
          >
            <div className="bg-[hsla(0,0%,100%,0.05)] border border-[hsla(0,0%,100%,0.05)] rounded-[45px] p-[5px] sm:p-2 z-19">
              <Button
                onClick={loginModal}
                className="items-center bg-gradient-to-t from-[#0064b8] to-[#0077db] p-[10px_12px] min-[1169px]:p-[11.162px_70px] min-[1169px]:mr-[3px] border border-[#2099ff] rounded-[35px] flex gap-[5px] h-[50px] text-white cursor-pointer text-[13px] font-medium whitespace-nowrap transition-all duration-200 hover:brightness-110"
              >
                <span>{L("homeBanner.loggedOut_banner.button", {})}</span>
                <CircleArrowIcon className="h-[25px] w-[25px]" />
              </Button>
            </div>

            <span className="text-[rgba(232,229,255,0.5)] text-[13px] hidden min-[1169px]:block lg:block">
              or
            </span>

            <div className="bg-[hsla(0,0%,100%,0.05)] flex gap-2 items-center border border-[hsla(0,0%,100%,0.05)] rounded-[45px] p-[5px] sm:p-2">
              <Button
                onClick={steam}
                className="items-center bg-[hsla(0,0%,100%,0.15)] border border-[hsla(0,0%,100%,0.1)] rounded-[30px] flex h-[50px] p-[15px] mr-[3px] transition-all duration-200 hover:bg-[hsla(0,0%,100%,0.25)]"
              >
                <SteamIcon className="w-5 text-white [&_path]:fill-white" />
              </Button>

              <Button
                onClick={metaMaskLogin}
                className="items-center bg-[hsla(0,0%,100%,0.15)] border border-[hsla(0,0%,100%,0.1)] rounded-[30px] flex h-[50px] p-[15px] mr-[3px] transition-all duration-200 hover:bg-[hsla(0,0%,100%,0.25)]"
              >
                <MetaMaskIcon />
              </Button>
            </div>
          </motion.div>
        </div>
      </div>

      <SnowingBackground
        snowflakeCount={15}
        className="relative z-409 pointer-events-none"
      />
      <motion.div
        className={cn("hidden @[987px]:block", chat ? "@[1550px]:block" : "")}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.5,
          ease: "easeInOut",
        }}
      >
        <Image
          alt="Character image"
          src={xmasCharacter}
          priority
          width={300}
          height={300}
          className={cn("absolute right-4 -bottom-5 z-10")}
        />
      </motion.div>
    </motion.div>
  );
}
