import React, { useMemo } from "react";

import useLanguage from "@hooks/useLanguage";
import { useUserSession } from "@hooks/useUserSession";
import CurrencyIcons from "@/items/CurrencyIcons";
import RewardIcons from "@items/RewardIcons";

import { SvgUnranked } from "../Svg/SvgStore";
import { numFormat } from "@/lib/tools/helpers";

import st from "./RankBanner.module.scss";
import { useWindowWidth } from "@hooks/useWindowWidth";
import { useRankProgress } from "@hooks/useRankProgress";
import { RankBannerSkeleton } from "./skeletons/RankBannerSkeleton";

export function RankBanner() {
  const L = useLanguage(["pageContent", "common"]);
  const { userData } = useUserSession();

  const { nextRank, userRank, progress } = useRankProgress();

  const isLoading = useMemo(() => {
    return !(userRank.totalWagered || nextRank.name);
  }, [userRank, nextRank]);

  const getRankLevel = (rankLevel) => {
    let level = "";

    switch (rankLevel) {
      case 1:
        return (level = "I");
      case 2:
        return (level = "II");
      case 3:
        return (level = "III");
      case 4:
        return (level = "IV");
      case 5:
        return (level = "V");
      case 6:
        return (level = "VI");
      default:
        return level;
    }
  };

  const currentRankLevel = getRankLevel(userRank?.level);
  const nextRankLevel = getRankLevel(nextRank?.level);
  const totalToNextRank = nextRank?.totalToNextRank;

  const currentWidth = useWindowWidth();

  if (isLoading) {
    return <RankBannerSkeleton />;
  }

  return (
    <div className={st["rank-banner-container"]}>
      <div className={st["title-container"]}>
        <h1
          className={`${st.h3} bg-clip-text text-transparent font-bold leading-snug`}
          style={{
            background: "linear-gradient(180deg, #FFF 48%, #C7C7C7 91.5%)",
            WebkitBackgroundClip: "text",
          }}
        >
          {L("homeBanner.loggedIn_banner.welcome")}{" "}
          <span className={st["username"]}>{userData?.username}</span>!
        </h1>
      </div>

      <div className={st["rank-container"]}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {nextRank?.name || !userRank?.name ? (
              <>
                <span className={st["rank-text"]}>
                  {L("homeBanner.rank_banner.progressTitle")}
                </span>

                <span
                  className={st["wagered-text"]}
                  style={{ flexWrap: "wrap" }}
                >
                  <CurrencyIcons size={"small"} style={{ display: "block" }} />
                  {numFormat(userRank?.totalWagered, false)}
                  <span className={st["rank-text"]}>
                    {" "}
                    / {numFormat(totalToNextRank, false)}
                    {""}{" "}
                    {currentWidth > 600 && L("homeBanner.rank_banner.wagered")}
                  </span>
                </span>
              </>
            ) : (
              <>
                <span className={st["rank-text"]}>
                  {L("homeBanner.rank_banner.wagered")}
                </span>

                <span
                  className={st["wagered-text"]}
                  style={{ flexWrap: "wrap" }}
                >
                  <CurrencyIcons size={"small"} style={{ display: "block" }} />
                  {userRank?.totalWagered}
                </span>
              </>
            )}
          </div>

          <div className={st["progress-bar-container"]}>
            <div className={st["progress-bar"]}>
              {userRank?.name || nextRank?.name === "unranked" ? (
                <div className={st["bar"]} style={{ width: `${progress}%` }} />
              ) : (
                <div className={st["bar"]} style={{ width: `${1}%` }} />
              )}
            </div>
          </div>

          <div className={st["user-rank-container"]}>
            <div style={{ alignItems: "center", display: "flex", gap: "5px" }}>
              {userRank?.name !== "unranked" ? (
                <RewardIcons code={userRank?.name} size="xlarge" />
              ) : (
                <SvgUnranked />
              )}

              <span className={st["user-rank"]}>
                {userRank?.name !== "unranked"
                  ? `${userRank.name} ${currentRankLevel}`
                  : L("homeBanner.rank_banner.unrank")}
              </span>
            </div>

            <span className={st["rank-text"]} style={{ flexWrap: "wrap" }}>
              <div style={{ alignItems: "center", display: "flex" }}>
                {userRank?.name ? (
                  <>
                    {nextRank?.name ? (
                      <RewardIcons code={nextRank?.name} size="xlarge" />
                    ) : null}
                  </>
                ) : (
                  <RewardIcons code="bronze" size="xlarge" />
                )}

                <span
                  className={st["user-rank"]}
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  {nextRank?.name
                    ? `${nextRank?.name} ${nextRankLevel}`
                    : userRank?.name
                      ? null
                      : L("homeBanner.rank_banner.rank")}
                </span>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
