import React from "react";
import Image from "next/image";
import st from "./SlotsBanner.module.scss";
import LeBandit from "@assets/Images/lebandit.jpg";
import Nft from "@assets/Images/nft.jpg";
import Princess from "@assets/Images/princess.jpg";
import PotOfFortune from "@assets/Images/potoffortune.jpg";
import Stormforged from "@assets/Images/stormforged.jpg";
import Wheel from "@assets/Images/wheel.jpg";
import Zeus from "@assets/Images/zeus.jpg";
const slotImages = [
  LeBandit,
  Nft,
  Princess,
  PotOfFortune,
  Stormforged,
  Wheel,
  Zeus,
];

export function SlotsBanner({ images }) {
  return (
    <>
      {images ? (
        <div className={st["container"]}>
          {slotImages?.map((image, index) => {
            return (
              <Image
                alt="Slots images"
                key={index}
                src={image}
                priority
                style={{ borderRadius: "6px" }}
              />
            );
          })}
        </div>
      ) : null}
    </>
  );
}
