import React from "react";
import { SlotsBanner } from "./SlotsBanner";
import { LinkTo } from "@components/LinkTo/LinkTo";
import {
  SvgArrowRight,
  SvgBasketball,
  SvgCaseBattles,
  SvgCaseBattlesRed,
  SvgCs2,
  SvgGroup,
  SvgSlots,
  SvgSoccer,
  SvgSportsbook,
  SvgSuspensionPoints,
  SvgTeam,
} from "@components/Svg/SvgStore";
import useLanguage from "@hooks/useLanguage";
import useMobile from "@hooks/useMobile";
import LeBandit from "@assets/Images/lebandit.jpg";
import Nft from "@assets/Images/nft.jpg";
import Princess from "@assets/Images/princess.jpg";
import PotOfFortune from "@assets/Images/potoffortune.jpg";
import Stormforged from "@assets/Images/stormforged.jpg";
import Wheel from "@assets/Images/wheel.jpg";
import Zeus from "@assets/Images/zeus.jpg";
import ZeusHades from "@assets/Images/zeus-vs-hades.jpg";
import st from "./Banner.module.scss";
import { useUserSession } from "@hooks/useUserSession";
import Image from "next/image";

export function Banner() {
  const L = useLanguage(["pageContent", "common"]);
  const { userData } = useUserSession();

  const slotImages = [
    LeBandit,
    Nft,
    Princess,
    PotOfFortune,
    Stormforged,
    Wheel,
    Zeus,
  ];

  const isMobile = useMobile({ breakPointThree: 764 });

  return (
    <>
      {(userData || (!userData && isMobile !== "mobile-three")) && (
        <div className={st["banner-container"]}>
          <div className={`${st["banner-box"]}`}>
            <LinkTo link="/casino/slots" className={st["banner-link"]}>
              <div className={st["banner-row"]}>
                <div className={st["row-heading-text"]}>
                  <div className={st["text-container"]}>
                    <div className={st["row-svg-title"]}>
                      <div className={st["svg-container"]}>
                        <SvgSlots />
                      </div>

                      <span>{L("homeBanner.slots_square.heading")}</span>

                      <div className={st["svg-container-arrow"]}>
                        <SvgArrowRight />
                      </div>
                    </div>

                    <div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: L("homeBanner.slots_square.subheading"),
                        }}
                        style={{ fontWeight: 400, wordBreak: "break-word" }}
                      />
                    </div>
                  </div>

                  <div className={st["row-images-container"]}>
                    <SlotsBanner images={slotImages} />

                    <div
                      className={st["svg-container-supension"]}
                      style={{ alignSelf: "center", display: "flex" }}
                    >
                      <SvgSuspensionPoints />
                    </div>
                  </div>
                </div>
              </div>
              <div className={st["row-image"]}>
                <Image
                  alt="Slot images"
                  src={ZeusHades}
                  width={36}
                  height={46}
                  priority
                  style={{ borderRadius: "6px", width: "36px", height: "45px" }}
                />
              </div>
            </LinkTo>

            <LinkTo link="/sportsbook" className={st["banner-link"]}>
              <div className={st["banner-row"]}>
                <div className={st["row-heading-text"]}>
                  <div className={st["text-container"]}>
                    <div className={st["row-svg-title"]}>
                      <div className={st["svg-container"]}>
                        <SvgSportsbook />
                      </div>

                      <span>{L("homeBanner.sportsbook_row.heading")}</span>

                      <div className={st["svg-container-arrow"]}>
                        <SvgArrowRight />
                      </div>
                    </div>

                    <div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: L("homeBanner.sportsbook_row.subheading"),
                        }}
                        style={{ fontWeight: 400, wordBreak: "break-word" }}
                      />
                    </div>
                  </div>

                  <div className={st["row-svg-games"]}>
                    <div className={st["border-games-banner"]}>
                      <SvgCs2 />

                      <div>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: L("homeBanner.sportsbook_row.sport1"),
                          }}
                        />
                      </div>
                    </div>

                    <div className={st["border-games-banner"]}>
                      <SvgBasketball />

                      <div>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: L("homeBanner.sportsbook_row.sport2"),
                          }}
                        />
                      </div>
                    </div>

                    <div className={st["border-games-banner"]}>
                      <SvgSoccer />

                      <div>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: L("homeBanner.sportsbook_row.sport3"),
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className={st["svg-container-supension"]}
                      style={{
                        alignSelf: "center",
                        display: "flex",
                        marginLeft: "10px",
                      }}
                    >
                      <SvgSuspensionPoints />
                    </div>
                  </div>
                </div>
              </div>
              <div className={st["row-image"]}>
                <div className={st["row-image-svg-container"]}>
                  <SvgSoccer key={`sportsBooks-soccer`} />
                </div>
              </div>
            </LinkTo>

            <LinkTo
              link="/casino/originals/case-battles"
              className={st["banner-link"]}
            >
              <div className={st["banner-row"]}>
                <div className={st["row-heading-text"]}>
                  <div className={st["text-container"]}>
                    <div className={st["row-svg-title"]}>
                      <div className={st["svg-container"]}>
                        <SvgCaseBattles />
                      </div>

                      <span>{L("homeBanner.casebattle_row.heading")}</span>

                      <div className={st["svg-container-arrow"]}>
                        <SvgArrowRight />
                      </div>
                    </div>

                    <p
                      dangerouslySetInnerHTML={{
                        __html: L("homeBanner.casebattle_row.subheading"),
                      }}
                      style={{ fontWeight: 400, wordBreak: "break-word" }}
                    />
                  </div>

                  <div className={st["row-svg-games"]}>
                    <div className={st["border-games-banner"]}>
                      <SvgCaseBattlesRed />

                      <div>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: L("homeBanner.casebattle_row.gameType1"),
                          }}
                        />
                      </div>
                    </div>

                    <div className={st["border-games-banner"]}>
                      <SvgGroup />

                      <div>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: L("homeBanner.casebattle_row.gameType2"),
                          }}
                        />
                      </div>
                    </div>

                    <div className={st["border-games-banner"]}>
                      <SvgTeam />

                      <div>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: L("homeBanner.casebattle_row.gameType3"),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={st["row-image"]}>
                <div className={st["row-image-svg-container"]}>
                  <SvgGroup key={`casebattles-group`} />{" "}
                </div>
              </div>
            </LinkTo>
          </div>
        </div>
      )}
    </>
  );
}
