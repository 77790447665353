import React from "react";

function SoundOn() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="15"
      fill="none"
      viewBox="0 0 18 15"
    >
      <path
        fill="#fff"
        d="M10.319.053a.532.532 0 00-.566.064l-5.2 4.17H2.07C1.48 4.286 1 4.766 1 5.356v4.286c0 .591.48 1.071 1.069 1.071h2.484l5.2 4.17a.539.539 0 00.566.062.533.533 0 00.302-.482V.536a.538.538 0 00-.302-.483zm3.008 3.659a.535.535 0 10-.751.763A4.22 4.22 0 0113.828 7.5a4.22 4.22 0 01-1.252 3.026.537.537 0 00.375.916.532.532 0 00.376-.155 5.274 5.274 0 001.57-3.787 5.283 5.283 0 00-1.57-3.788z"
      ></path>
      <path
        fill="#fff"
        d="M14.835 2.202a.534.534 0 10-.754.761A6.35 6.35 0 0115.966 7.5c0 1.719-.67 3.33-1.885 4.537a.54.54 0 00-.003.758.537.537 0 00.757.002 7.408 7.408 0 002.2-5.297c0-2.006-.78-3.888-2.2-5.298z"
      ></path>
    </svg>
  );
}

export default SoundOn;
