import useSWR from "swr";
import { useUserSession } from "./useUserSession";

const useRewards = () => {
  const { addTokenHeader, hasToken } = useUserSession();

  return useSWR(
    hasToken ? "user/rewards" : null,
    (url) => API.get(url, addTokenHeader()).then((res) => res.data),
    {
      keepPreviousData: true,
      // revalidate every 5 minutes
      refreshInterval: 300000,
      revalidateOnFocus: false,
    },
  );
};

export default useRewards;
