export function SvgPaymentMethod() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" fill="none">
      <g clipPath="url(#clip0_7900_32186)">
        <mask
          id="mask0_7900_32186"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="25"
          height="25"
        >
          <path d="M24.2499 24.25V0.750072H0.75V24.25H24.2499Z" fill="white" />

          <path
            d="M24.2499 24.25V0.750072H0.75V24.25H24.2499Z"
            stroke="white"
            strokeWidth="1.5"
          />
        </mask>

        <g mask="url(#mask0_7900_32186)">
          <path
            d="M12.5 0.976629L2.92969 4.95011V9.78041C2.92969 16.0316 6.71234 21.6611 12.5 24.0234C18.2876 21.6611 22.0703 16.0316 22.0703 9.78041V4.95011L12.5 0.976629Z"
            fill="url(#paint0_linear_7900_32186)"
            fillOpacity="0.1"
          />

          <path
            d="M12.5 0.976629L2.92969 4.95011V9.78041C2.92969 16.0316 6.71234 21.6611 12.5 24.0234C18.2876 21.6611 22.0703 16.0316 22.0703 9.78041V4.95011L12.5 0.976629Z"
            stroke="black"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />

          <path
            d="M12.5 0.976629L2.92969 4.95011V9.78041C2.92969 16.0316 6.71234 21.6611 12.5 24.0234C18.2876 21.6611 22.0703 16.0316 22.0703 9.78041V4.95011L12.5 0.976629Z"
            stroke="url(#paint1_linear_7900_32186)"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />

          <path
            d="M8.69141 11.7239L11.4206 14.4531L16.3086 9.5652"
            fill="url(#paint2_linear_7900_32186)"
            fillOpacity="0.1"
          />

          <path
            d="M8.69141 11.7239L11.4206 14.4531L16.3086 9.5652"
            stroke="black"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />

          <path
            d="M8.69141 11.7239L11.4206 14.4531L16.3086 9.5652"
            stroke="url(#paint3_linear_7900_32186)"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>

      <defs>
        <linearGradient
          id="paint0_linear_7900_32186"
          x1="12.5"
          y1="24.0234"
          x2="12.5"
          y2="0.976629"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#87C1F2" />

          <stop offset="1" stopColor="#3A8DDA" />
        </linearGradient>

        <linearGradient
          id="paint1_linear_7900_32186"
          x1="12.5"
          y1="24.0234"
          x2="12.5"
          y2="0.976629"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#87C1F2" />

          <stop offset="1" stopColor="#3A8DDA" />
        </linearGradient>

        <linearGradient
          id="paint2_linear_7900_32186"
          x1="12.5"
          y1="14.4531"
          x2="12.5"
          y2="9.5652"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#87C1F2" />

          <stop offset="1" stopColor="#3A8DDA" />
        </linearGradient>

        <linearGradient
          id="paint3_linear_7900_32186"
          x1="12.5"
          y1="14.4531"
          x2="12.5"
          y2="9.5652"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#87C1F2" />

          <stop offset="1" stopColor="#3A8DDA" />
        </linearGradient>

        <clipPath id="clip0_7900_32186">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
