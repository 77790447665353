import GameDescription from "@Games/GameDescription";
import MetaContent from "@items/MetaContent";
import API from "@lib/api/api";
import { NODE_API } from "@lib/api/api";
import { getClientIp } from "@lib/tools/helpers";
import { logError } from "@lib/tools/logger";
import { fetchPageContent } from "@lib/tools/ssr";
import Custom404 from "@pages/404";
import dynamic from "next/dynamic";
import { memo, useMemo } from "react";
import GameHeading from "@Games/GameHeading";

// Dynamically imported game components
const CaseOpen = dynamic(() => import("@Games/CaseOpen"));
const Dice = dynamic(() => import("@Games/Dice"));
const Plinko = dynamic(() => import("@Games/Plinko"));
const Roulette = dynamic(() => import("@Games/Roulette"));
const CaseBattles = dynamic(() => import("@Games/CaseBattles"));
const Keno = dynamic(() => import("@Games/Keno"));
const Mines = dynamic(() => import("@Games/Mines"));
const Blackjack = dynamic(() => import("@Games/Blackjack"));

export const gameImageMap = {
  "case-battles": `${process.env.NEXT_PUBLIC_RAINBET_CDN}/games/case-battle.png`,
  "case-opening": `${process.env.NEXT_PUBLIC_RAINBET_CDN}/games/case-unboxing.png`,
  crash: `${process.env.NEXT_PUBLIC_RAINBET_CDN}/games/crash.png`,
  dice: `${process.env.NEXT_PUBLIC_RAINBET_CDN}/games/dice.png`,
  plinko: `${process.env.NEXT_PUBLIC_RAINBET_CDN}/games/plinko.png`,
  roulette: `${process.env.NEXT_PUBLIC_RAINBET_CDN}/games/roulette.png`,
  keno: `${process.env.NEXT_PUBLIC_RAINBET_CDN}/games/keno.png`,
  mines: `${process.env.NEXT_PUBLIC_RAINBET_CDN}/games/mines.png`,
  blackjack: `${process.env.NEXT_PUBLIC_RAINBET_CDN}/games/blackjack.png`,
};

const Game = ({
  gameUrl,
  cases,
  meta,
  caseBattlesData,
  requestTime,
  minesCheckData,
}) => {
  const GameComponent = useMemo(() => {
    const gameMapping = {
      "case-opening": (
        <>
          <GameHeading />
          <CaseOpen gameDetails={meta} casesData={cases} specialCases={false} />
        </>
      ),
      "case-battles": (
        <>
          <GameHeading />
          <CaseBattles
            gameDetails={meta}
            casesData={cases}
            specialCases={false}
            caseBattlesDataInitial={caseBattlesData}
          />
        </>
      ),
      dice: (
        <>
          <GameHeading />
          <Dice />
        </>
      ),
      plinko: (
        <>
          <GameHeading />
          <Plinko />
        </>
      ),
      roulette: (
        <>
          <GameHeading />
          <Roulette requestTime={requestTime} />
        </>
      ),
      keno: (
        <>
          <Keno />
        </>
      ),
      "mines-game": (
        <>
          <Mines minesCheckData={minesCheckData} />
        </>
      ),
      blackjack: (
        <>
          <Blackjack />
        </>
      ),
    };

    return gameMapping[gameUrl] || <Custom404 />;
  }, [gameUrl, meta, cases, caseBattlesData, requestTime, minesCheckData]);

  return (
    <>
      {GameComponent}
      {gameUrl &&
        gameImageMap[gameUrl === "mines-game" ? "mines" : gameUrl] && (
          <>
            <GameDescription
              gameDetails={meta}
              gameImage={
                gameImageMap[gameUrl === "mines-game" ? "mines" : gameUrl]
              }
              meta={meta}
            />
          </>
        )}
    </>
  );
};

const Games = (props) => {
  const { meta } = props;

  return (
    <>
      <MetaContent meta={meta} />
      <Game {...props} />
    </>
  );
};

export async function getServerSideProps(context) {
  try {
    const { authOptions } = await import("@pages/api/auth/[...nextauth]");
    const { getServerSession } = await import("next-auth");
    const session = await getServerSession(
      context.req,
      context.res,
      authOptions
    ).catch(() => null);
    const token = session?.user?.access_token;

    const headers = {
      "ssr-secret-yel8wd1sa": process.env.SSR_SECRET,
      "user-agent": context.req.headers["user-agent"],
      cookie: context.req.headers.cookie,
      "ssr-client-ip": getClientIp(context),
      ...(token && { Authorization: `Bearer ${token}` }),
    };

    const [meta, cases, caseBattlesData, minesCheckData] = await Promise.all([
      fetchPageContent(context),
      API.get("public/case-list?type=balance", { headers })
        .then((res) => res.data)
        .then((data) => (data.success ? data.result.cases : [])),

      context.query.game === "case-battles"
        ? NODE_API.get("/v1/public/case_battles_quick_lobbies", {
            headers,
          }).then((res) =>
            res.data.reduce((acc, lobby) => {
              acc[lobby.lobbyId] = lobby.gameData;
              return acc;
            }, {})
          )
        : null,

      context.query.game === "mines-game" && token
        ? NODE_API.get("/v1/games/mines/check-game", {
            baseURL: process.env.NEXT_PUBLIC_API_NODE_GAMES,
            headers,
          })
            .then((res) => res.data)
            .catch((error) => {
              logError("Error checking mines game:", error);
              return null;
            })
            .finally(() => {
              //Make sure the mines game is not cached
              context.res.setHeader("Cache-Control", "no-store");
            })
        : null,
    ]);

    return {
      props: {
        cases,
        meta,
        gameUrl: context.query.game,
        caseBattlesData,
        minesCheckData,
      },
    };
  } catch (error) {
    logError("Error fetching game data:", error);
    return {
      notFound: true,
    };
  }
}

export default memo(Games);
