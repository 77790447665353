import { SVGProps } from "react";

export default function CircleArrowIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="33"
      fill="none"
      viewBox="0 0 34 33"
      {...props}
    >
      <g filter="url(#filter0_d_3839_114294)">
        <path
          fill="#fff"
          d="M31.167 15.5c0-7.82-6.346-14.166-14.166-14.166S2.834 7.681 2.834 15.501c0 7.82 6.347 14.166 14.167 14.166 7.82 0 14.166-6.346 14.166-14.166zm-14.166 3.953v-2.536h-4.25a1.42 1.42 0 01-1.417-1.416c0-.78.638-1.417 1.417-1.417H17v-2.536c0-.637.765-.949 1.204-.496l3.952 3.953a.701.701 0 010 1.006l-3.952 3.952a.709.709 0 01-1.204-.51z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d_3839_114294"
          width="34.334"
          height="34.334"
          x="-0.166"
          y="0.334"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3839_114294"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3839_114294"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}
