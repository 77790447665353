import useLanguage from "../hooks/useLanguage";
import Button from "../items/Button";
import st from "../styles/pages/Custom404.module.scss";
import useMobile from "../hooks/useMobile";
import MetaContent from "../items/MetaContent";
import { logError } from "@lib/tools/logger";
import { fetchPageContent } from "@lib/tools/ssr";

export default function Custom404({ content }) {
  const isMobile = useMobile();
  const L = useLanguage(["PageNotFound"]);
  return (
    <>
      <MetaContent meta={content?.meta || { title: "404 – Rainbet" }} />
      <div
        className={`${st["error-page-container"]} ${isMobile && st[isMobile]}`}
      >
        <h1 className={`${st["custom-title"]}`}>404</h1>
        <h4>{L("page_not_found")}</h4>
        <p>
          {L("page_moved")}
          <br />
          {L("back_homepage")}
        </p>
        <Button
          buttonType={"link"}
          url={{
            pathname: "/",
          }}
          text={L("home_page")}
          classType={["page-not-found"]}
        />
        <div className={st["round-effect"]}></div>
      </div>
    </>
  );
}

export async function getStaticProps(context) {
  try {
    const content = await fetchPageContent(context);

    return {
      props: {
        content,
      },
    };
  } catch (err) {
    logError(err);
    return {
      props: {},
    };
  }
}
