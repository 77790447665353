export function SvgWeeklyCalendar() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1568 1.507H19.8642V0.706656C19.8642 0.315391 19.5469 -0.00195312 19.1556 -0.00195312C18.7643 -0.00195312 18.447 0.315391 18.447 0.706656V1.507H17.0544V0.706656C17.0544 0.315391 16.7371 -0.00195312 16.3456 -0.00195312C15.9543 -0.00195312 15.637 0.315391 15.637 0.706656V1.507H7.81181V0.706656C7.81181 0.315391 7.49447 -0.00195312 7.10306 -0.00195312C6.71175 -0.00195312 6.39441 0.315391 6.39441 0.706656V1.507H5.00189V0.706656C5.00189 0.315391 4.68455 -0.00195312 4.29309 -0.00195312C3.90178 -0.00195312 3.58448 0.315391 3.58448 0.706656V1.507H2.29172C1.02816 1.507 0 2.53516 0 3.79895V21.7063C0 22.9699 1.02816 23.998 2.29172 23.998H21.1568C22.4205 23.998 23.4485 22.9699 23.4485 21.7063V3.79891C23.4485 2.53511 22.4204 1.50695 21.1568 1.50695V1.507ZM22.0311 21.7063C22.0311 22.1884 21.6389 22.5808 21.1568 22.5808H2.29172C1.80956 22.5808 1.41717 22.1884 1.41717 21.7063V7.39961H22.031L22.0311 21.7063ZM1.41722 3.79895C1.41722 3.31661 1.80961 2.92445 2.29177 2.92445H3.58448V3.7248C3.58448 4.11611 3.90183 4.43341 4.29323 4.43341C4.68455 4.43341 5.00189 4.11606 5.00189 3.7248V2.92445H6.39441V3.7248C6.39441 4.11611 6.71175 4.43341 7.1032 4.43341C7.49452 4.43341 7.81181 4.11606 7.81181 3.7248V2.92445H15.637V3.7248C15.637 4.11611 15.9544 4.43341 16.3458 4.43341C16.7371 4.43341 17.0544 4.11606 17.0544 3.7248V2.92445H18.447V3.7248C18.447 4.11611 18.7643 4.43341 19.1556 4.43341C19.5471 4.43341 19.8644 4.11606 19.8644 3.7248V2.92445H21.1567C21.6389 2.92445 22.0313 3.31666 22.0313 3.79895V5.98211H1.41722V3.79895Z"
        fill="url(#paint0_linear_12870_73003)"
      />
      <mask
        id="path-2-outside-1_12870_73003"
        maskUnits="userSpaceOnUse"
        x="8.59668"
        y="10.8574"
        width="7"
        height="9"
        fill="black"
      >
        <rect fill="white" x="8.59668" y="10.8574" width="7" height="9" />
        <path d="M10.6043 18.8574L13.6161 12.1043L13.9705 12.7087H9.83313L10.4793 12.0522V13.9489H9.21826V11.5623H14.8876V12.469L12.0529 18.8574H10.6043Z" />
      </mask>
      <path
        d="M10.6043 18.8574L13.6161 12.1043L13.9705 12.7087H9.83313L10.4793 12.0522V13.9489H9.21826V11.5623H14.8876V12.469L12.0529 18.8574H10.6043Z"
        fill="white"
      />
      <path
        d="M10.6043 18.8574L13.6161 12.1043L13.9705 12.7087H9.83313L10.4793 12.0522V13.9489H9.21826V11.5623H14.8876V12.469L12.0529 18.8574H10.6043Z"
        fill="url(#paint1_linear_12870_73003)"
      />
      <path
        d="M10.6043 18.8574L13.6161 12.1043L13.9705 12.7087H9.83313L10.4793 12.0522V13.9489H9.21826V11.5623H14.8876V12.469L12.0529 18.8574H10.6043Z"
        fill="url(#paint2_linear_12870_73003)"
      />
      <path
        d="M10.6043 18.8574L10.4616 18.7937L10.3634 19.0137H10.6043V18.8574ZM13.6161 12.1043L13.751 12.0252L13.5972 11.7629L13.4734 12.0406L13.6161 12.1043ZM13.9705 12.7087V12.865H14.2433L14.1053 12.6297L13.9705 12.7087ZM9.83313 12.7087L9.72171 12.5991L9.45996 12.865H9.83313V12.7087ZM10.4793 12.0522H10.6356V11.6704L10.3678 11.9425L10.4793 12.0522ZM10.4793 13.9489V14.1052H10.6356V13.9489H10.4793ZM9.21826 13.9489H9.06194V14.1052H9.21826V13.9489ZM9.21826 11.5623V11.406H9.06194V11.5623H9.21826ZM14.8876 11.5623H15.0439V11.406H14.8876V11.5623ZM14.8876 12.469L15.0305 12.5324L15.0439 12.5021V12.469H14.8876ZM12.0529 18.8574V19.0137H12.1546L12.1958 18.9208L12.0529 18.8574ZM10.7471 18.9211L13.7589 12.1679L13.4734 12.0406L10.4616 18.7937L10.7471 18.9211ZM13.4813 12.1833L13.8356 12.7878L14.1053 12.6297L13.751 12.0252L13.4813 12.1833ZM13.9705 12.5524H9.83313V12.865H13.9705V12.5524ZM9.94455 12.8184L10.5907 12.1618L10.3678 11.9425L9.72171 12.5991L9.94455 12.8184ZM10.3229 12.0522V13.9489H10.6356V12.0522H10.3229ZM10.4793 13.7926H9.21826V14.1052H10.4793V13.7926ZM9.37458 13.9489V11.5623H9.06194V13.9489H9.37458ZM9.21826 11.7187H14.8876V11.406H9.21826V11.7187ZM14.7313 11.5623V12.469H15.0439V11.5623H14.7313ZM14.7447 12.4056L11.91 18.794L12.1958 18.9208L15.0305 12.5324L14.7447 12.4056ZM12.0529 18.7011H10.6043V19.0137H12.0529V18.7011Z"
        fill="url(#paint3_linear_12870_73003)"
        mask="url(#path-2-outside-1_12870_73003)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12870_73003"
          x1="11.7242"
          y1="23.998"
          x2="11.7242"
          y2="-0.00195312"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#33A9FF" />
          <stop offset="1" stopColor="#6FFA8E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12870_73003"
          x1="12.0967"
          y1="21.8574"
          x2="12.0967"
          y2="8.85742"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#42A9FF" />
          <stop offset="1" stopColor="#83D1FC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_12870_73003"
          x1="12.0967"
          y1="21.8574"
          x2="12.0967"
          y2="8.85742"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#33A9FF" />
          <stop offset="1" stopColor="#A9FBCA" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_12870_73003"
          x1="12.0967"
          y1="21.8574"
          x2="12.0967"
          y2="8.85742"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#42A9FF" />
          <stop offset="1" stopColor="#83D1FC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
