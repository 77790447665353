import React, { useCallback, useEffect, useState } from "react";

import useEmblaCarousel from "embla-carousel-react";

import {
  CarouselButtonNext,
  CarouselButtonPrev,
  CarouselDotButton,
} from "@components/Carousel/CarouselButton";

import st from "./CarouselHomeBanners.module.scss";
import { useWindowWidth } from "@hooks/useWindowWidth";

export function CarouselHomeBanners({ emblaRef, children }) {
  const currentWidth = useWindowWidth();

  const [viewportRef, embla] = useEmblaCarousel({
    align: "start",
    axis: "y",
    containScroll: "trimSnaps",
    skipSnaps: false,
  });

  const [state, setState] = useState({
    stateNextBtnEnabled: false,
    statePrevBtnEnabled: false,
    stateSelectedIndex: 0,
    scrollSnaps: [],
  });

  const {
    scrollSnaps,
    stateNextBtnEnabled,
    statePrevBtnEnabled,
    stateSelectedIndex,
  } = state;

  const scrollNext = useCallback(() => embla?.scrollNext?.(), [embla]);
  const scrollPrev = useCallback(() => embla?.scrollPrev?.(), [embla]);
  const scrollTo = useCallback((index) => embla?.scrollTo?.(index), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;

    setState({
      scrollSnaps: embla.scrollSnapList(),
      stateNextBtnEnabled: embla.canScrollNext(),
      statePrevBtnEnabled: embla.canScrollPrev(),
      stateSelectedIndex: embla.selectedScrollSnap(),
    });
  }, [embla]);

  useEffect(() => {
    embla?.reInit?.();

    if (emblaRef) emblaRef.current = embla;
  }, [embla, emblaRef]);

  useEffect(() => {
    onSelect();

    embla?.on("select", onSelect);

    return () => {
      embla?.off("select", onSelect);
    };
  }, [embla, onSelect]);

  return (
    <div className={st["carousel-container"]}>
      <div className={st["carousel-viewport"]} ref={viewportRef}>
        <div className={st["carousel-inner-container"]}>{children}</div>
      </div>
      <div
        style={{
          display: "flex",
          height: "auto",
          paddingLeft: "20px",
          paddingRight: "20px",
          position: "absolute",
          right: 0,
          top: "-62px",
          width: "auto",
        }}
      >
        <CarouselButtonPrev
          disabled={!statePrevBtnEnabled}
          onClick={scrollPrev}
          style={{ display: "none" }}
        />

        <div style={{ height: "8px", width: "8px" }} />

        <CarouselButtonNext
          disabled={!stateNextBtnEnabled}
          onClick={scrollNext}
          style={{ display: "none" }}
        />
      </div>

      <div
        style={
          currentWidth < 670
            ? {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                position: "absolute",
                width: "100%",
                bottom: 4,
                gap: 9,
                zIndex: 1,
                left: "-11px",
              }
            : {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                position: "absolute",
                top: "50%",
                zIndex: 1,
              }
        }
      >
        {scrollSnaps.map((num, index) => (
          <CarouselDotButton
            active={index === stateSelectedIndex}
            key={num}
            onClick={() => scrollTo(index)}
          />
        ))}
      </div>
    </div>
  );
}
