import React from "react";

function SoundOff() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="17"
      fill="none"
      viewBox="0 0 19 17"
    >
      <path
        fill="#fff"
        d="M10.34 1.053a.534.534 0 00-.568.064l-5.21 4.17h-2.49C1.48 5.286 1 5.766 1 6.356v4.286c0 .591.481 1.071 1.071 1.071h2.49l5.21 4.17a.54.54 0 00.568.062.534.534 0 00.303-.482V1.536a.538.538 0 00-.303-.483zm3.014 3.659a.537.537 0 00-.753.763c.81.799 1.256 1.874 1.256 3.025a4.215 4.215 0 01-1.256 3.026.536.536 0 00.753.76A5.269 5.269 0 0014.928 8.5a5.277 5.277 0 00-1.574-3.788z"
      ></path>
      <path
        fill="#fff"
        d="M14.866 3.202a.536.536 0 00-.755.761A6.343 6.343 0 0116 8.5a6.34 6.34 0 01-1.889 4.537.538.538 0 00-.003.758.539.539 0 00.758.002A7.4 7.4 0 0017.071 8.5a7.404 7.404 0 00-2.205-5.298z"
      ></path>
      <path
        stroke="#252A45"
        strokeWidth="2"
        d="M2.607 1.068L17.608 16.068"
      ></path>
      <path stroke="#fff" strokeWidth="1.5" d="M1.53 1.847L15.53 15.847"></path>
    </svg>
  );
}

export default SoundOff;
